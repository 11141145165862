.skill {
  flex-direction: row;
  text-align: left;
}

.skill img {
  height: 115px;
  background-color: var(--alabaster);
  padding: 15px;
  border-radius: 12px;
  border: 3px solid var(--green-sheen);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6);
}

@media (max-width: 768px) {
  .skill {
    flex-direction: column;
    text-align: center;
  }
}
