/* CSS HEX */
:root{
    --alabaster: #E6E0D7ff;
    --platinum: #D8E1DCff;
    --morning-blue: #8EAFA1ff;
    --green-sheen: #7CA794ff;
    --celadon-green: #36827F;
    --illuminating-emerald: #4D9977ff;
    --hookers-green: #4F7268ff;
    --brunswick-green: #245145ff;
    --charleston-green: #2B2F2Eff;
    
    --indian-red: #de5b5f;
    }