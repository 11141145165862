.card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 30px;
    background-color: var(--brunswick-green);
    color: var(--alabaster);
    border-radius: 12px;
    line-height: 1.25;
    padding: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6);
  }

.card h2 {
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 0;
}
.card hr {
  margin-top: 5px;
  margin-bottom: 30px;
  border-style: solid;
  border-color: var(--green-sheen);
}
.card p {
  margin-bottom: 15px;
}
.card a {
  text-decoration: none;
  color: var(--indian-red);
  font-weight: 600;
  transition: color 300ms;
}
.card a:hover {
  color: var(--green-sheen);
  text-decoration: underline;
}