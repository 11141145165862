.image-header,
.text-header {
  background-color: var(--charleston-green);
  margin-bottom: 60px;
}

.text-header > div {
  width: 100%;
  justify-content: center;
}
.text-header .text{
  text-align: center;
}

.image-header > div {
  display: flex;
  flex-direction: row;
  padding: 0;
}

.image {
  width: 50%;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.text {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto 25px;
}

.text h1 {
  color: var(--alabaster);
  font-size: 4rem;
  line-height: 1.2;
  font-family: "Dancing Script", cursive;
}

.text h2 {
  color: var(--green-sheen);
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .image-header > div{
    flex-direction: column-reverse;
    width: 100%;
  }
  .text {
    margin: 25px;
    text-align: center;
  }
  .image-header .image {
    height: 35vh;
    width: 100%;
  }
}
