.app-card {
  margin: auto;
}
.app-card a {
  cursor: pointer;
}
.app-card img {
  height: 300px;
  width: 300px;
  background-color: var(--alabaster);
  padding: 15px;
  border-radius: 12px;
  border: 3px solid var(--green-sheen);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6);
  filter: brightness(0.5);
  transition: filter 300ms, border-color 300ms;
}
.app-card img:hover {
  cursor: pointer;
  border-color: var(--indian-red);
  filter: none;
}

@media (max-width: 768px) {
  .app-card {
    flex-direction: column;
    min-width: 300px;
    max-width: 90%;
  }
  .app-card img {
    filter: none;
    width: 75%;
    height: auto;
  }
}
