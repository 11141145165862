.footer {
    width: 100%;
    min-height: 100px;
    background: var(--charleston-green);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    margin-top: 60px;
}
.footer h2 {
    font-size: 1rem;
    margin: 0;
}

.social-media {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }

  .social-media svg {
    font-size: 1.5rem;
    color: var(--green-sheen);
    transition: color 300ms;
  }
  .social-media svg:hover{
    color: var(--indian-red);
    cursor: pointer;
  }