/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Montserrat&display=swap");

/* General */
* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}
html,
body,
#root {
  height: 100%;
}
body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  background-color: var(--platinum);
}
.content{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page-container {
  text-align: center;
  flex: 1;
}
h1,
h2 {
  margin-bottom: 15px;
}
